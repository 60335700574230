<ng-container *ngIf="!fnolSubmitted"
    ><ng-container *ngIf="!activeClaim"
        ><div class="card border-0 br-75 mb-3">
            <div class="card-body">
                <form data-cy="fnolForm" [formGroup]="fnolForm">
                    <div class="row">
                        <div class="col-sm-6 col-12">
                            <div class="form-floating mb-3">
                                <input class="form-control" id="firstNameInput" data-cy="firstNameInput" autocomplete="off" type="text" aria-describedby="firstNameHelp" sbwAutoFocus formControlName="firstName" [class.is-invalid]="firstNameControlInvalid" placeholder="firstName" />
                                <div class="invalid-feedback" *ngIf='firstNameControl.hasError("required")'>First Name required.</div>
                                <label class="text-gray-600 small" for="firstNameInput">First Name</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-12">
                            <div class="form-floating mb-3">
                                <input class="form-control" id="lastNameInput" data-cy="lastNameInput" autocomplete="off" type="text" aria-describedby="lastNameHelp" formControlName="lastName" [class.is-invalid]="lastNameControlInvalid" placeholder="lastName" />
                                <div class="invalid-feedback" *ngIf='lastNameControl.hasError("required")'>Last Name required.</div>
                                <label class="text-gray-600 small" for="lastNameInput">Last Name</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-12">
                            <div class="form-floating mb-3">
                                <input class="form-control" id="emailInput" data-cy="emailInput" autocomplete="off" type="text" aria-describedby="emailHelp" formControlName="email" [class.is-invalid]="emailControlInvalid" placeholder="email" />
                                <div class="invalid-feedback" *ngIf='emailControl.hasError("required")'>Email required.</div>
                                <div class="invalid-feedback" *ngIf='emailControl.hasError("email")'>Email is not valid.</div>
                                <label class="text-gray-600 small" for="emailInput">Email</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-12">
                            <div class="form-floating mb-3">
                                <input class="form-control" id="phoneInput" data-cy="phoneInput" autocomplete="off" type="text" pattern="[0-9]*" aria-describedby="phoneHelp" formControlName="phone" [class.is-invalid]="phoneControlInvalid" placeholder="phone" mask="(000) 000-0000" />
                                <div class="invalid-feedback" *ngIf='phoneControl.hasError("required")'>Phone required.</div>
                                <div class="invalid-feedback" *ngIf='phoneControl.hasError("mask")'>Phone is not valid.</div>
                                <label class="text-gray-600 small" for="phoneInput">Phone</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-12">
                            <div class="form-floating mb-3"><input class="form-control" id="policyNumberInput" data-cy="policyNumberInput" autocomplete="off" type="text" aria-describedby="policyNumberHelp" formControlName="policyNumber" placeholder="policyNumber" /><label class="text-gray-600 small" for="policyNumberInput">Policy Number (If Available)</label></div>
                        </div>
                        <div class="col-sm-6 col-12">
                            <div class="form-floating mb-3">
                                <input class="form-control" id="dateOfLossInput" data-cy="dateOfLossInput" autocomplete="off" type="date" [min]="minDateOfLoss" [max]="maxDateOfLoss" aria-describedby="dateOfLossHelp" formControlName="dateOfLoss" [class.is-invalid]="dateOfLossControlInvalid" placeholder="dateOfLoss" />
                                <div class="invalid-feedback" *ngIf='dateOfLossControl.hasError("required")'>Date of Loss required.</div>
                                <div class="invalid-feedback" *ngIf='dateOfLossControl.hasError("notWithinInterval")'>Date of Loss must be within the last 30 days</div>
                                <label class="text-gray-600 small" for="dateOfLossInput">Date of Loss</label>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-floating mb-3">
                                <textarea class="form-control" id="lossDetailsInput" data-cy="lossDetailsInput" autocomplete="off" autosize [minRows]="1" type="text" aria-describedby="lossDetailsHelp" formControlName="lossDetails" [class.is-invalid]="lossDetailsControlInvalid" placeholder="lossDetails"></textarea>
                                <div class="invalid-feedback" *ngIf='lossDetailsControl.hasError("required")'>Loss Details required.</div>
                                <label class="text-gray-600 small" for="lossDetailsInput">Loss Details </label>
                            </div>
                        </div>
                        <div class="col-12">
                            <button class="btn btn-xl rounded-pill btn-primary-gradient quote-next-button mb-5 mt-md-5 mt-3 w-100" data-cy="startClaimButton" type="button" (click)="startClaim()" tabindex="0" [attr.disabled]="!allValid ? true : null"><div class="d-flex align-items-center justify-content-center">Start Claim</div></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="text-center r-text-lg text-primary mb-3">or</div>
        <div class="card border-0 br-75 mb-5">
            <div class="card-body">
                <div class="d-flex flex-column justify-content-between align-items-center my-3">
                    <div class="r-text-base mb-3">Call us directly to start the claim process.</div>
                    <div class="d-flex justify-content-start align-items-center">
                        <div class="d-inline-block text-primary me-3"><i-bs name="telephone" width="2em" height="2em"></i-bs></div>
                        <div class="d-flex justify-content-between align-items-start flex-column"><a class="d-inline-block align-bottom lh-30 text-baby-blue text-xl" [href]='"tel:" + tredderMainPhoneNumberHref'>{{tredderMainPhoneNumber}}</a></div>
                    </div>
                </div>
            </div>
        </div></ng-container
    ><ng-container *ngIf="activeClaim"
        ><div class="card border-0 br-75 mb-5">
            <div class="card-body">
                <div class="d-flex flex-column justify-content-between align-items-center my-3">
                    <div class="r-text-base mb-3">Call us directly if you need to reach us about your claim.</div>
                    <div class="d-flex justify-content-start align-items-center">
                        <div class="d-inline-block text-primary me-3"><i-bs name="telephone" width="2em" height="2em"></i-bs></div>
                        <div class="d-flex justify-content-between align-items-start flex-column"><a class="d-inline-block align-bottom lh-30 text-baby-blue text-xl" [href]='"tel:" + tredderMainPhoneNumberHref'>{{tredderMainPhoneNumber}}</a></div>
                    </div>
                </div>
            </div>
        </div></ng-container
    ></ng-container
><ng-container *ngIf="fnolSubmitted"
    ><div class="card border-0 br-75 mb-5">
        <div class="card-body">
            <div class="d-flex flex-column justify-content-between align-items-center my-3"><div class="r-text-base text-primary mb-3">Great! A claims specialist will be in touch with you shortly to discuss your claim. Please be prepared with photos and a description of the incident that caused the damage and when and where it occurred.</div></div>
        </div>
    </div></ng-container
>
