import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppShellComponent } from './app-shell/app-shell.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('modules/site/site-routing.module').then((m) => m.SiteRoutingModule),
    },
    {
        path: 'quote',
        loadChildren: () =>
            import('modules/quote/quote-routing.module').then((m) => m.QuoteRoutingModule),
    },
    {
        path: 'estimate',
        loadChildren: () =>
            import('modules/estimate/estimate-routing.module').then((m) => m.EstimateRoutingModule),
    },
    {
        path: 'rig',
        loadChildren: () =>
            import('modules/rig/rig-routing.module').then((m) => m.RigRoutingModule),
    },
    // {
    //     path: 'rigs',
    //     loadChildren: () =>
    //         import('modules/rig/rigs-routing.module').then((m) => m.RigRoutingModule),
    // },
    {
        path: 'account',
        loadChildren: () =>
            import('modules/account/account-routing.module').then((m) => m.AccountRoutingModule),
    },
    {
        path: 'admin',
        loadChildren: () =>
            import('modules/admin/admin-routing.module').then((m) => m.AdminRoutingModule),
    },
    {
        path: 'affiliate',
        loadChildren: () =>
            import('modules/affiliate/affiliate-routing.module').then(
                (m) => m.AffiliateRoutingModule
            ),
    },
    {
        path: 'agency',
        loadChildren: () =>
            import('modules/agency/agency-routing.module').then((m) => m.AgencyRoutingModule),
    },
    {
        path: 'manage-common',
        loadChildren: () =>
            import('modules/manage-common/manage-common-routing.module').then(
                (m) => m.ManageCommonRoutingModule
            ),
    },
    {
        path: 'partner',
        loadChildren: () =>
            import('modules/partner/partner-routing.module').then((m) => m.PartnerRoutingModule),
    },
    {
        path: 'splash',
        loadChildren: () =>
            import('modules/splash/splash-routing.module').then((m) => m.SplashRoutingModule),
    },
    {
        path: 'brand',
        loadChildren: () =>
            import('modules/brand/brand-routing.module').then((m) => m.BrandRoutingModule),
    },
    {
        path: 'dev',
        loadChildren: () =>
            import('modules/dev/dev-routing.module').then((m) => m.DevRoutingModule),
    },
    {
        path: 'auth',
        loadChildren: () =>
            import('modules/auth/auth-routing.module').then((m) => m.AuthRoutingModule),
    },
    {
        path: 'roc',
        loadChildren: () =>
            import('modules/roc/roc-routing.module').then((m) => m.RocRoutingModule),
    },
    {
        path: 'error',
        loadChildren: () =>
            import('modules/error/error-routing.module').then((m) => m.ErrorRoutingModule),
    },
    {
        path: 'shell',
        component: AppShellComponent,
    },
    {
        path: '',
        loadChildren: () =>
            import('modules/app-common/app-common-routing.module').then(
                (m) => m.AppCommonRoutingModule
            ),
    },
    {
        path: '',
        loadChildren: () =>
            import('modules/landing-pages/landing-pages-routing.module').then(
                (m) => m.LandingPagesRoutingModule
            ),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            initialNavigation: 'enabledBlocking',
            // enableTracing: true,
        }),
    ],
    exports: [],
})
export class AppRoutingModule {}
