export interface ValueLimits {
    mods: ValueLimitsParams;
    camper: ValueLimitsParams;
    trailer: ValueLimitsParams;
    gear: ValueLimitsParams;
}

export interface ValueLimitsParams {
    min: number;
    max: number;
    override: number;
    roundedTo: number;
}

export const valueLimits: ValueLimits = {
    mods: {
        min: 5000,
        max: 150000, // 150k,
        override: 250000, // 250k
        roundedTo: 500,
    },
    camper: {
        min: 5000,
        max: 1000000, // 1 million, //250k is cap, but we let them enter and then say to call
        override: 1000000, // 1m
        roundedTo: 1000,
    },
    trailer: {
        min: 5000,
        max: 1000000, // 1 million, //250k is cap, but we let them enter and then say to call
        override: 1000000, // 1m
        roundedTo: 1000,
    },
    gear: {
        min: 1000,
        max: 25000, // 25k,
        override: 100000, // 100k,
        roundedTo: 100,
    },
};

// export interface ValueLimits {
//     modsValueMax: number;
//     camperValueMax: number;
//     trailerValueMax: number;
//     gearValueMax: number;
// }

// export const valueLimits: ValueLimits = {
//     modsValueMax: 250000, // 250k
//     camperValueMax: 1000000, // 1 million
//     trailerValueMax: 1000000, // 1 million
//     gearValueMax: 100000, // 100k
// };
