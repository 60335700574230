import { ExistingCoveragePullStatus } from '@backend-types/coverage-verification';
import { DriverMaritalStatus } from '@backend-types/driver';
import {
    GearCoverageType,
    OwnOrLease,
    QuoteEnsFlowFormValue,
    RigStorageMethod,
    VehicleUse,
} from '@backend-types/quote-flow-ens';
import { VehicleType } from '@backend-types/rig';
import {
    AcpeDeductible,
    CampsiteLiabilityLimit,
    EmergencyExpenseLimit,
    ENSTermRateKey,
    OffRoadRecoveryLimit,
    RocRigOwnedTenure,
    RocUnitClass,
    ScheduledMedicalBenefitLimit,
    TowRoadsideLimit,
    UnitCollDeductible,
    UnitCompDeductible,
} from '@backend-types/roc-lib';
import { ModelFormValue } from '@common/models';

export const quoteEnsFlowFormDefaultTestValues: ModelFormValue<QuoteEnsFlowFormValue> = {
    rigSets: [
        {
            camperDetails: {
                unitValue: 10000,
                unitLength: 10,
                unitAge: 10,
                unitOwnedTenure: 10,
                unitMake: 'CAMPER MAKE',
                unitModel: 'CAMPER MODEL',
                unitClass: RocUnitClass['TRUCK MOUNTED CAMPER'],
                unitCompDeductible: UnitCompDeductible.fiveHundred,
                unitCollDeductible: UnitCollDeductible.fiveHundred,
            },
            trailerDetails: {
                unitValue: 10000,
                unitLength: 10,
                unitAge: 10,
                unitOwnedTenure: 10,
                unitMake: 'TRAILER MAKE',
                unitModel: 'TRAILER MODEL',
                unitClass: RocUnitClass['TENT/CAMPING TRAILER'],
                unitCompDeductible: UnitCompDeductible.fiveHundred,
                unitCollDeductible: UnitCollDeductible.fiveHundred,
            },
            modsDetails: {
                modsValue: 10000,
                modsDeductible: AcpeDeductible.fiveHundred,
            },
            rigName: 'Rigatoni',
            vehicleType: VehicleType.none,
            rigDetails: {
                year: 2019,
                make: 'Ram',
                model: '1500',
                trim: 'Laramie',
                yearsOwned: RocRigOwnedTenure.threeYearsToFiveYears,
                ownOrLease: OwnOrLease.own,
                vehicleUse: VehicleUse.pleasure,
                storageMethod: RigStorageMethod.driveway,
                vin: null,
                forQuickQuote: false,
            },
            coverageSelect: {
                modsCoverage: true,
                camperCoverage: true,
                trailerCoverage: true,
            },
            rigId: null,
        },
    ],
    gearCoverage: true,
    gearDetails: {
        gearValue: 10000,
        gearCoverageType: GearCoverageType.actualCashValue,
    },
    homeBase: {
        street: 'Yosemite National Park Road',
        zip: '95389',
        city: 'Yosemite Valley',
        state: 'CA',
        country: 'US',
    },
    ensPersonalInfo: {
        firstName: 'Testy',
        lastName: 'McTestface',
        birthday: '1978-05-06',
        email: `test_${new Date().valueOf()}@test.test`,
        phone: '5551234567',
        tcpaAgree: true,
        ssn: null,
        maritalStatus: DriverMaritalStatus.married,
    },
    specialCoverages: {
        emergencyExpense: {
            selected: true,
            emergencyExpenseLimit: EmergencyExpenseLimit.oneThousand,
        },
        scheduledMedicalBenefit: {
            selected: false,
            scheduledMedicalBenefitLimit: ScheduledMedicalBenefitLimit.sixtyThousand,
        },
        towRoadside: {
            selected: true,
            towRoadsideLimit: TowRoadsideLimit.fiveHundred,
        },
        campsiteLiability: {
            selected: false,
            campsiteLiabilityLimit: CampsiteLiabilityLimit.fiftyThousand,
        },
        offRoadRecovery: {
            selected: true,
            offRoadRecoveryLimit: OffRoadRecoveryLimit.fiveThousand,
        },
    },
    pledge: true,
    autoBillingOptIn: true,
    documentsAcknowledged: false,
    electronicDocuments: true,
    term: ENSTermRateKey.sixMonth,
    timeZone: 'America/Los_Angeles',
    effectiveDate: null,
    fromPolicyId: null,
    eSignatureConsentGiven: null,
    ipAddress: null,
    selectedPaymentPlan: null,
    rc2: null,
    organizationId: null,
    agentId: null,
    existingCoveragePullStatus: ExistingCoveragePullStatus.notPulled,
    existingCoverageManual: null,
    quotePolicyKind: null,
    utm: null,
    quoteAdditionalDrivers: null,
    quotePrincipalDriver: null,
    estimateId: null,
    modsValueRangeOverride: null,
    camperValueRangeOverride: null,
    trailerValueRangeOverride: null,
    gearValueRangeOverride: null,
};
