<div class="container-xxl" *ngIf="!formOnly">
    <div class="text-center">
        <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-3">
            <div class="d-md-none d-block">
                <div class="quote-title">What do you want</div>
                <div class="quote-title">to cover on</div>
                <div class="quote-title">{{activeQuoteRigSetForm?.value.rigName}}?</div>
            </div>
            <div class="d-md-block d-none"><div class="quote-title">What do you want to cover on {{activeQuoteRigSetForm?.value.rigName}}</div></div>
        </div>
    </div>
    <ng-template [ngTemplateOutlet]="coverageSelectFormTemplate"></ng-template>
    <div class="text-center">
        <button class="btn btn-xl rounded-pill btn-primary-gradient quote-next-button mb-5 mt-md-5 mt-3" data-cy="quoteFlowCoverageSelectNextButton" type="button" (click)="next.emit()" tabindex="0" [attr.disabled]="(noneSelected || gearOnlyError) ? true : null">
            <div class="d-flex align-items-center justify-content-center">Next</div>
            <sbf-tredder-icons name="arrow-right" height="18px"></sbf-tredder-icons>
        </button>
    </div>
    <div class="d-flex justify-content-start"><sbf-back-button data-cy="quoteFlowCoverageSelectBackButton" (click)="back.emit()"></sbf-back-button></div>
</div>
<ng-container *ngIf="formOnly"><ng-template [ngTemplateOutlet]="coverageSelectFormTemplate"></ng-template></ng-container
><ng-template #coverageSelectFormTemplate
    ><form class="row p-3 justify-content-center" data-cy="coverageSelectForm" [formGroup]="coverageSelectForm">
        <div class="text-center p-2 position-relative col-md-6" [ngClass]='{"col-lg-3": showGearCoverageSelect, "col-lg-4": !showGearCoverageSelect}'>
            <div class="card coverage-select-card pointer" (click)="activeQuoteRigSetForm.controls.modsDetails.valid &amp;&amp; modsCoverageControl.setValue(!modsCoverageControl.value)" [ngClass]="{selected: modsCoverageControl.value}">
                <div class="card-body p-0">
                    <img src="/assets/img/svg/overland-design/od_mods.svg" alt="Vehicle Modifications" />
                    <div class="text-primary mb-1">Mods</div>
                    <sbf-input-checkbox class="mb-3" data-cy="modsCoverageInput" #modsCoverage idAppend="modsCoverage" [double]="true" [bgAlt]="true" formControlName="modsCoverage"></sbf-input-checkbox>
                </div>
            </div>
            <button class="btn btn-sm btn-secondary text-white btn-icon d-inline p-0 question-button position-absolute" type="button" (click)='showHelp("modsCoverage")'><i-bs class="d-inline-block" name="question" width="1.5rem" height="1.5rem"></i-bs></button>
        </div>
        <div class="text-center p-2 position-relative col-md-6" [ngClass]='{"col-lg-3": showGearCoverageSelect, "col-lg-4": !showGearCoverageSelect}'>
            <div class="card coverage-select-card pointer" (click)="activeQuoteRigSetForm.controls.camperDetails.valid &amp;&amp; camperCoverageControl.setValue(!camperCoverageControl.value)" [ngClass]="{selected: camperCoverageControl.value}">
                <div class="card-body p-0">
                    <img src="/assets/img/svg/overland-design/od_camper.svg" alt="Camper" />
                    <div class="text-primary mb-1">Camper</div>
                    <sbf-input-checkbox class="mb-3" data-cy="camperCoverageInput" idAppend="camperCoverage" [double]="true" [bgAlt]="true" formControlName="camperCoverage"></sbf-input-checkbox>
                </div>
            </div>
            <button class="btn btn-sm btn-secondary text-white btn-icon d-inline p-0 question-button position-absolute" type="button" (click)='showHelp("camperCoverage")'><i-bs class="d-inline-block" name="question" width="1.5rem" height="1.5rem"></i-bs></button>
        </div>
        <div class="text-center p-2 position-relative col-md-6" [ngClass]='{"col-lg-3": showGearCoverageSelect, "col-lg-4": !showGearCoverageSelect}'>
            <div class="card coverage-select-card pointer" (click)="activeQuoteRigSetForm.controls.trailerDetails.valid &amp;&amp; trailerCoverageControl.setValue(!trailerCoverageControl.value)" [ngClass]="{selected: trailerCoverageControl.value}">
                <div class="card-body p-0">
                    <img src="/assets/img/svg/overland-design/od_trailer.svg" alt="Trailer" />
                    <div class="text-primary mb-1">Trailer</div>
                    <sbf-input-checkbox class="mb-3" data-cy="trailerCoverageInput" idAppend="trailerCoverage" [double]="true" [bgAlt]="true" formControlName="trailerCoverage"></sbf-input-checkbox>
                </div>
            </div>
            <button class="btn btn-sm btn-secondary text-white btn-icon d-inline p-0 question-button position-absolute" type="button" (click)='showHelp("trailerCoverage")'><i-bs class="d-inline-block" name="question" width="1.5rem" height="1.5rem"></i-bs></button>
        </div>
        <div class="col-lg-3 text-center p-2 position-relative col-md-6" *ngIf="showGearCoverageSelect">
            <div class="card coverage-select-card pointer" (click)="quoteEnsFlowForm.controls.gearDetails.valid  &amp;&amp; gearCoverageControl.setValue(!gearCoverageControl.value)" [ngClass]="{selected: gearCoverageControl.value}">
                <div class="card-body p-0">
                    <img src="/assets/img/svg/overland-design/od_gear.svg" alt="Gear" />
                    <div class="text-primary mb-1">Gear</div>
                    <sbf-input-checkbox class="mb-3" data-cy="gearCoverageInput" idAppend="gearCoverage" [double]="true" [bgAlt]="true" formControlName="gearCoverage"></sbf-input-checkbox>
                </div>
            </div>
            <button class="btn btn-sm btn-secondary text-white btn-icon d-inline p-0 question-button position-absolute" type="button" (click)='showHelp("gearCoverage")'><i-bs class="d-inline-block" name="question" width="1.5rem" height="1.5rem"></i-bs></button>
        </div>
    </form>
    <div class="text-center text-danger" data-cy="gearOnlyErrorFeedback" *ngIf="gearOnlyError">Gear can only be insured along with Mods, Camper or Trailer</div></ng-template
>
