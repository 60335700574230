import type { RunEnvironment, RunEnvironmentRole } from './internal';
import type { PaymentPlanName } from './payment-plan';
import type { PolicyAnalytics, PolicyCancelationReason } from './policy-ens';
import type { PremiumDynamicOwedPaid } from './roc';

export declare type PaymentErrorCodes =
    | 'ERROR_CREATING_PAYMENT_INTENT'
    | 'ERROR_UPDATING_PAYMENT_INTENT'
    | 'ERROR_CREATING_STRIPE_CUSTOMER'
    | 'ERROR_CREATING_STRIPE_SUBSCRIPTION'
    | 'ERROR_NO_CLIENT_SECRET'
    | 'ERROR_CANCELING_STRIPE_SUBSCRIPTION';

export enum ProcessorName {
    stripe = 'stripe',
}

export interface ProcessorInfo<T extends ProcessorName = ProcessorName> {
    name: T;
    data: ProcessorData[T];
}

export type ProcessorData = {
    stripe: ProcessorDataStripe;
};

export interface RefundMetaData {
    [key: string]: RunEnvironment | RunEnvironmentRole | RefundMetaDataData;

    data: RefundMetaDataData;
    runEnvironment: RunEnvironment;
    runEnvironmentRole: RunEnvironmentRole;
}

export type RefundMetaDataData =
    | RefundMetaDataDataForEndorsement
    | RefundMetaDataDataForInvoice
    | RefundMetaDataDataForCancel;

export interface RefundMetaDataDataForEndorsement {
    refundReason: RefundReason.endorsement;
    refundInvoiceId: UUID;
    quoteEnsId: UUID;
    sourcePaymentIntentId: string;
    existingPolicyId: UUID;
}

export interface RefundMetaDataDataForInvoice {
    refundReason: RefundReason.invoice;
    invoiceId: UUID;
    sourcePaymentIntentId: string;
    amount: string;
}

export interface RefundMetaDataDataForCancel {
    refundReason: RefundReason.cancel;
    cancelationReason: PolicyCancelationReason;
    refundInvoiceId: UUID;
    sourcePaymentIntentId: string;
    amount: string;
}

export interface ProcessorDataStripe {
    eventId?: string;
    paymentIntentId: string;
    refundId?: string;
    refundMetaData?: RefundMetaDataData;
}

export interface PayFirstInstallmentPayload {
    quoteID: UUID;
    stripeToken: string;
    paymentPlanName: PaymentPlanName;
    policyAnalytics: PolicyAnalytics;
}

export interface PayFirstInstallmentResponse {
    result: string; // PaymentResult
    mustConfirm: boolean;
}

export enum PaymentResult {
    success = 'success',
    failure = 'failure',
}

export enum PaymentIntentKind {
    ensFirstInstallment = 'ensFirstInstallment',
    invoicePayment = 'invoicePayment',
}

export interface CreatePaymentIntentPayload<T extends PaymentIntentKind = PaymentIntentKind> {
    kind: T;
    data: PaymentIntentData[T];
}

export type PaymentIntentData = {
    ensFirstInstallment: {
        quoteEnsId: string;
        paymentPlanName: PaymentPlanName;
        paymentReason: PaymentReason;
    };
    invoicePayment: {
        invoiceId: UUID;
        policyWrapperId: UUID;
        organizationId: UUID;
        initiatingUserId: UUID | null;
    };
};

export interface CreatePaymentIntentResponse {
    clientSecret: string;
}

export interface WebhookStripeResponse {
    received: true;
    runEnvironmentMismatch?: true;
    runEnvironment?: string;
    runEnvironmentRole?: string;
}

export enum PaymentReason {
    newPolicy = 'newPolicy',
    endorsement = 'endorsement',
    renewal = 'renewal',
    installment = 'installment',
    refund = 'refund',
}

export type PaymentReasonDescriptionMap = {
    [index in PaymentReason]: string;
};

export const paymentReasonDescriptionMap: PaymentReasonDescriptionMap = {
    newPolicy: 'Payment for Tredder',
    endorsement: 'Endorsement Payment for Tredder',
    renewal: 'Renewal Payment for Tredder',
    installment: 'Installment Payment for Tredder',
    refund: 'Refund From Tredder',
};

export interface RetrievePaymentMethodResponse {
    methods: Array<PaymentMethodCard | PaymentMethodACH>;
}

export enum SupportedPaymentMethods {
    ach = 'us_bank_account',
    card = 'card',
}

export interface PaymentMethodCard {
    type: SupportedPaymentMethods.card;
    card: {
        brand: string | null;
        last4: string | null;
    };
}

export interface PaymentMethodACH {
    type: SupportedPaymentMethods.ach;
    us_bank_account: {
        account_type: 'checking' | 'savings' | null;
        bank_name: string | null;
        last4: string | null;
    };
}

export enum RefundReason {
    endorsement = 'endorsement',
    invoice = 'invoice',
    cancel = 'cancel',
}

export type CreateRefundPayload =
    | CreateRefundEndorsementPayload
    | CreateRefundInvoicePayload
    | CreateRefundCancelationPayload;

export interface CreateRefundEndorsementPayload {
    reason: RefundReason.endorsement;
    data: {
        quoteEnsId: UUID;
    };
}

export interface CreateRefundInvoicePayload {
    reason: RefundReason.invoice;
    data: {
        invoiceId: UUID;
        amount?: number;
    };
}

export interface CreateRefundCancelationPayload {
    reason: RefundReason.cancel;
    data: CreateRefundCancelationPayloadData;
}

export interface CreateRefundCancelationPayloadData {
    policyEnsId: UUID;
    dollarAmount: number;
    premiumDynamicOwedPaid: PremiumDynamicOwedPaid;
}

export interface RefundResponse {
    success: boolean;
}
