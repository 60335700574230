import type { BuildInfo } from '@common/models/build-info.model';

export const buildInfo: BuildInfo = {
    commitSHA: '13585475993b3108071ba3628dbf5201ab47251a',
    branch: 'main',
    deployEnv: 'prod',
    version: '1.2.3',
    dateFormatted: '2025-01-24--22-08',
    dateISOString: '2025-01-24T22:08:44.476Z',
};
