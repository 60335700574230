import type { PolicyEns, PolicyWrapper, Prisma } from './_prisma';
import type { ClaimTyped } from './claim';
import type { ExistingCoveragePullStatus } from './coverage-verification';
import type { PolicyEnsGeneratedDocuments, UploadedDocument } from './document';
import type { DriverTyped } from './driver';
import type { ExistingCoverageTyped } from './existing-coverage';
import type { GearTyped } from './gear';
import type { InvoiceForBillingTyped } from './invoice';
import { invoiceForBillingInclude } from './invoice';
import type { PaymentPlanTyped } from './payment-plan';
import { policyEventWithInitiatorInclude } from './policy-event';
import type { QuoteEnsTyped, QuotePolicyKind } from './quote-ens';
import type { ReceiptForBillingTyped } from './receipt';
import type { RigWithModificationsTyped } from './rig';
import type { ENSTermRateKey, FinalRates } from './roc-lib';
import type { StateShort } from './states';
import { userForBaseInclude, type UserForBaseSelect } from './user';

export type PolicyEnsForFormat = Prisma.PolicyEnsGetPayload<{
    include: {
        policyWrapper: true;
    };
}>;

export type PolicyEnsWithWrapper = Prisma.PolicyEnsGetPayload<{
    include: {
        policyWrapper: true;
        rigs: {
            include: { modifications: true };
        };
        organization: {
            select: {
                id: true;
                name: true;
            };
        };
        user: {
            select: UserForBaseSelect;
        };
        agent: {
            select: UserForBaseSelect;
        };
        existingCoverages: true;
        principalDriver: true;
        additionalDrivers: true;
    };
}>;

export type PolicyEnsForResults = PolicyEnsWithWrapper;

export const policyEnsWithWrapperInclude = {
    policyWrapper: true,
    rigs: {
        include: {
            modifications: true,
        },
    },
    organization: {
        select: {
            id: true,
            name: true,
        },
    },
    user: userForBaseInclude,
    agent: userForBaseInclude,
    existingCoverages: true,
    principalDriver: true,
    additionalDrivers: true,
};

export type PolicyEnsWithQuote = Prisma.PolicyEnsGetPayload<{
    include: {
        policyWrapper: true;
        rigs: true;
        quoteEns: true;
        sourceQuoteEns: true;
        organization: {
            select: {
                id: true;
                name: true;
            };
        };
        user: {
            select: UserForBaseSelect;
        };
        agent: {
            select: UserForBaseSelect;
        };
    };
}>;

export const policyEnsWithQuoteInclude = {
    policyWrapper: true,
    quoteEns: true,
    rigs: true,
    sourceQuoteEns: true,
    organization: {
        select: {
            id: true,
            name: true,
        },
    },
    user: userForBaseInclude,
    agent: userForBaseInclude,
};

export type PolicyEnsForBilling = Prisma.PolicyEnsGetPayload<{
    include: {
        policyWrapper: {
            include: {
                policyEvents: {
                    include: {
                        initiatingUser: {
                            select: {
                                id: true;
                                firstName: true;
                                lastName: true;
                                email: true;
                                phone: true;
                            };
                        };
                    };
                };
            };
        };
        rigs: true;
        campers: true;
        trailers: true;
        gearList: true;
        quoteEns: true;
        invoices: {
            include: {
                ledgerEntry: true;
                policyWrapper: true;
                policyEns: {
                    include: {
                        policyWrapper: true;
                    };
                };
                lineItems: true;
                organization: {
                    select: {
                        id: true;
                        name: true;
                    };
                };
                receipt: {
                    include: {
                        organization: true;
                        ledgerEntry: true;
                    };
                };
                user: {
                    select: UserForBaseSelect;
                };
            };
        };
        existingCoverages: true;
        paymentPlan: true;
        sourceQuoteEns: true;
        successor: true;
        organization: {
            select: {
                id: true;
                name: true;
            };
        };
        user: {
            select: UserForBaseSelect;
        };
        agent: {
            select: UserForBaseSelect;
        };
        principalDriver: true;
        additionalDrivers: true;
        claims: true;
    };
}>;

export const policyEnsForBillingInclude = {
    policyWrapper: {
        include: {
            policyEvents: {
                include: policyEventWithInitiatorInclude,
            },
        },
    },
    rigs: true,
    campers: true,
    trailers: true,
    gearList: true,
    quoteEns: true,
    invoices: { include: invoiceForBillingInclude },
    existingCoverages: true,
    paymentPlan: true,
    sourceQuoteEns: true,
    successor: true,
    organization: {
        select: {
            id: true,
            name: true,
        },
    },
    user: userForBaseInclude,
    agent: userForBaseInclude,
    principalDriver: true,
    additionalDrivers: true,
    claims: true,
};

export interface CreatePolicyEnsPayload {
    iteration: number;
    total: string;
    stateTaxesTotal: string;
    stateFeesTotal: string;
    tredderFeesTotal: string;
    term: ENSTermRateKey;
    effectiveDate: string;
    expirationDate: string;
    underwritingEndDate: string;
    timeZone: string;
    policyKind: QuotePolicyKind;
    policyStatus: PolicyStatus;
    reinsurer: PolicyReInsurer;
    tpa: PolicyTPA;
    treatyAgreement: PolicyTreatyAgreement;
    commissionTerms: CommissionTerms;
    frontingFee: FrontingFee;
    policyAnalytics: PolicyAnalytics;
    policyEnsDocuments: PolicyEnsGeneratedDocuments;
    finalRates: FinalRates;
    autoBilling: boolean;
    paperlessBilling: boolean;
    userId: string;
    principalDriverId: string;
    rigIds: string[];
    quoteEnsId: string;
    conversationId: string;
    policyWrapperId: string;
    organizationId: string;
    existingCoveragePullStatus: ExistingCoveragePullStatus;
}

export interface ReadMultiplePoliciesEnsPayload {
    policyEnsIDs: string[];
}

export interface DeleteMultiplePoliciesEnsPayload {
    policyEnsIDs?: string[];
}

export interface UpdatePolicyEnsPayload {
    iteration?: number;
    total?: string;
    stateTaxesTotal?: string;
    stateFeesTotal?: string;
    tredderFeesTotal?: string;
    term?: ENSTermRateKey;
    effectiveDate?: string;
    expirationDate?: string;
    underwritingEndDate?: string;
    timeZone?: string;
    policyKind?: QuotePolicyKind;
    policyStatus?: PolicyStatus;
    reinsurer?: PolicyReInsurer;
    tpa?: PolicyTPA;
    treatyAgreement?: PolicyTreatyAgreement;
    commissionTerms?: CommissionTerms;
    frontingFee?: FrontingFee;
    policyAnalytics?: PolicyAnalytics;
    policyEnsDocuments?: PolicyEnsGeneratedDocuments;
    finalRates?: FinalRates;
    autoBilling?: boolean;
    paperlessBilling?: boolean;
    userId?: string;
    rigIds?: string[];
    quoteEnsId?: string;
    policyWrapperId?: string;
    existingCoveragePullStatus?: ExistingCoveragePullStatus;
}

export type AllPoliciesEnsErrorCodes = 'ERROR_FINDING_POLICIES_ENS' | 'POLICIES_ENS_NOT_FOUND';
export type CreatePoliciesEnsErrorCodes = 'POLICY_ENS_NAME_IN_USE' | 'ERROR_CREATING_POLICY_ENS';
export type DeleteMultiplePoliciesEnsErrorCodes =
    | 'POLICIES_ENS_NOT_FOUND'
    | 'ERROR_FINDING_POLICIES_ENS'
    | 'ERROR_DELETING_POLICIES_ENS';
export type DeletePolicyEnsErrorCodes =
    | 'POLICY_ENS_NOT_FOUND'
    | 'ERROR_FINDING_POLICY_ENS'
    | 'ERROR_DELETING_POLICY_ENS';
export type FindPolicyEnsErrorCodes = 'ERROR_FINDING_POLICY_ENS' | 'POLICY_ENS_NOT_FOUND';
export type FindPoliciesEnsErrorCodes = 'ERROR_FINDING_POLICIES_ENS' | 'NO_POLICIES_ENS_FOUND';
export type SavePolicyEnsErrorCodes = 'ERROR_SAVING_POLICY_ENS';
export type UpdatePolicyEnsErrorCodes = 'ERROR_UPDATING_POLICY_ENS' | 'POLICY_ENS_NOT_FOUND';

export enum PolicyStatus {
    none = 'none',
    active = 'active',
    canceled = 'canceled', // may have successor and create predecessor
    // reinstated = 'reinstated' // wont actually be a state. will be back to active
    endorsed = 'endorsed', // will have successor and create predecessor
    // Ending States
    lapsed = 'lapsed', // Policy ended without renewal or endorsement
    renewed = 'renewed', // will have successor and create predecessor
}

export enum PolicyReInsurer {
    tokioMarineKiln = 'tokioMarineKiln',
}

export enum PolicyTPA {
    reserv = 'reserv',
}

export enum PolicyTreatyAgreement {
    hcc2024 = 'hcc2024',
}

export enum CommissionTerms {
    twelveTwelve = 'twelveTwelve',
}

export enum FrontingFee {
    fivePercent = 'fivePercent',
}

// PolicyAnalytics
export interface PolicyAnalytics<T extends PolicyAnalyticsName = PolicyAnalyticsName> {
    name: T;
    data: PolicyAnalyticsData[T];
}

export enum PolicyAnalyticsName {
    direct = 'direct',
    channel = 'channel',
    broker = 'broker',
}

export type PolicyAnalyticsData = {
    direct: PolicyAnalyticsDataDirect;
    channel: PolicyAnalyticsDataChannel;
    broker: PolicyAnalyticsDataBroker;
};

export interface PolicyAnalyticsDataDirect {}

export interface PolicyAnalyticsDataChannel {
    channelName: string;
    channelID: string;
}

export interface PolicyAnalyticsDataBroker {
    brokerName: string;
    brokerID: string;
}

export interface PolicyEnsTyped
    extends Omit<
        PolicyEns,
        | 'total'
        | 'stateTaxesTotal'
        | 'stateFeesTotal'
        | 'tredderFeesTotal'
        | 'policyAnalytics'
        | 'policyEnsDocuments'
        | 'finalRates'
        | 'uploadedDocuments'
    > {
    term: ENSTermRateKey;
    policyKind: QuotePolicyKind;
    policyStatus: PolicyStatus;
    reinsurer: PolicyReInsurer;
    tpa: PolicyTPA;
    treatyAgreement: PolicyTreatyAgreement;
    policyWrapper: PolicyWrapper;
    rigs: RigWithModificationsTyped[];
    total: string;
    stateTaxesTotal: string;
    stateFeesTotal: string;
    tredderFeesTotal: string;
    policyAnalytics: PolicyAnalytics;
    policyEnsDocuments: PolicyEnsGeneratedDocuments;
    finalRates: FinalRates;
    uploadedDocuments: UploadedDocument[] | null;
    existingCoveragePullStatus: ExistingCoveragePullStatus;
    cancelationReason: PolicyCancelationReason | null;
}

export interface PolicyEnsForFormatTyped
    extends Omit<
        PolicyEnsForFormat,
        | 'total'
        | 'stateTaxesTotal'
        | 'stateFeesTotal'
        | 'tredderFeesTotal'
        | 'policyAnalytics'
        | 'policyEnsDocuments'
        | 'finalRates'
        | 'uploadedDocuments'
    > {
    total: string;
    stateTaxesTotal: string;
    stateFeesTotal: string;
    tredderFeesTotal: string;
    policyAnalytics: PolicyAnalytics;
    policyEnsDocuments: PolicyEnsGeneratedDocuments;
    finalRates: FinalRates;
    uploadedDocuments: UploadedDocument[] | null;
}

export interface PolicyEnsForResultsTyped
    extends Omit<
        PolicyEnsForResults,
        | 'total'
        | 'stateTaxesTotal'
        | 'stateFeesTotal'
        | 'tredderFeesTotal'
        | 'policyAnalytics'
        | 'policyEnsDocuments'
        | 'finalRates'
        | 'uploadedDocuments'
        | 'rigs'
        | 'existingCoverages'
    > {
    term: ENSTermRateKey;
    policyKind: QuotePolicyKind;
    policyStatus: PolicyStatus;
    reinsurer: PolicyReInsurer;
    tpa: PolicyTPA;
    treatyAgreement: PolicyTreatyAgreement;
    policyWrapper: PolicyWrapper;
    rigs: RigWithModificationsTyped[];
    total: string;
    stateTaxesTotal: string;
    stateFeesTotal: string;
    tredderFeesTotal: string;
    policyAnalytics: PolicyAnalytics;
    policyEnsDocuments: PolicyEnsGeneratedDocuments;
    finalRates: FinalRates;
    uploadedDocuments: UploadedDocument[] | null;
    existingCoveragePullStatus: ExistingCoveragePullStatus;
    cancelationReason: PolicyCancelationReason | null;
    existingCoverages: ExistingCoverageTyped[];
    principalDriver: DriverTyped;
    additionalDrivers: DriverTyped[];
}

export interface PolicyEnsWithQuoteTyped
    extends Omit<
        PolicyEnsWithQuote,
        | 'quoteEns'
        | 'sourceQuoteEns'
        | 'total'
        | 'stateTaxesTotal'
        | 'stateFeesTotal'
        | 'tredderFeesTotal'
        | 'policyAnalytics'
        | 'policyEnsDocuments'
        | 'finalRates'
        | 'uploadedDocuments'
        | 'rigs'
    > {
    term: ENSTermRateKey;
    policyKind: QuotePolicyKind;
    policyStatus: PolicyStatus;
    quoteEns: QuoteEnsTyped;
    reinsurer: PolicyReInsurer;
    tpa: PolicyTPA;
    treatyAgreement: PolicyTreatyAgreement;
    policyWrapper: PolicyWrapper;
    rigs: RigWithModificationsTyped[];
    total: string;
    stateTaxesTotal: string;
    stateFeesTotal: string;
    tredderFeesTotal: string;
    sourceQuoteEns?: QuoteEnsTyped;
    policyAnalytics: PolicyAnalytics;
    policyEnsDocuments: PolicyEnsGeneratedDocuments;
    finalRates: FinalRates;
    uploadedDocuments: UploadedDocument[] | null;
    existingCoveragePullStatus: ExistingCoveragePullStatus;
    cancelationReason: PolicyCancelationReason | null;
}

export interface PolicyEnsForBillingTyped
    extends Omit<
        PolicyEnsForBilling,
        | 'quoteEns'
        | 'sourceQuoteEns'
        | 'paymentPlan'
        | 'invoices'
        | 'total'
        | 'stateTaxesTotal'
        | 'stateFeesTotal'
        | 'tredderFeesTotal'
        | 'policyAnalytics'
        | 'policyEnsDocuments'
        | 'finalRates'
        | 'uploadedDocuments'
        | 'rigs'
        | 'gearList'
        | 'existingCoverages'
        | 'claims'
    > {
    term: ENSTermRateKey;
    policyKind: QuotePolicyKind;
    policyStatus: PolicyStatus;
    reinsurer: PolicyReInsurer;
    tpa: PolicyTPA;
    treatyAgreement: PolicyTreatyAgreement;
    policyWrapper: PolicyWrapperWithEvent;
    rigs: RigWithModificationsTyped[];
    quoteEns: QuoteEnsTyped;
    paymentPlan: PaymentPlanTyped;
    invoices: InvoiceForBillingTyped[];
    receipts: ReceiptForBillingTyped[];
    total: string;
    stateTaxesTotal: string;
    stateFeesTotal: string;
    tredderFeesTotal: string;
    sourceQuoteEns?: QuoteEnsTyped;
    policyAnalytics: PolicyAnalytics;
    policyEnsDocuments: PolicyEnsGeneratedDocuments;
    finalRates: FinalRates;
    uploadedDocuments: UploadedDocument[] | null;
    existingCoverages: ExistingCoverageTyped[];
    existingCoveragePullStatus: ExistingCoveragePullStatus;
    cancelationReason: PolicyCancelationReason | null;
    gearList: GearTyped[];
    principalDriver: DriverTyped;
    additionalDrivers: DriverTyped[];
    claims: ClaimTyped[];
}

export type ActivePolicyEnsTyped =
    | PolicyEnsTyped
    | PolicyEnsForResultsTyped
    | PolicyEnsWithQuoteTyped
    | PolicyEnsForBillingTyped;

// // PolicyEvent
// export interface PolicyEvent<T extends PolicyEventName> {
//     name: T;
//     timestamp: Date;
//     data: PolicyEventData[T];
// }

// export enum PolicyEventName {
//     created = 'created',
//     payment = 'payment',
//     lapsed = 'lapsed',
//     endorsement = 'endorsement',
// }

// export type PolicyEventData = {
//     created: PolicyEventDataCreated;
//     payment: PolicyEventDataPayment;
//     lapsed: PolicyEventDataLapsed;
//     endorsement: PolicyEventDataEndorsement;
// };

// export interface PolicyEventDataCreated {
//     effectiveDate: Date;
//     expirationDate: Date;
// }

// export interface PolicyEventDataPayment {
//     stripePaymentID: string;
//     paymentAmount: Date;
// }

// export interface PolicyEventDataLapsed {}

// export interface PolicyEventDataEndorsement {}

export interface PolicyDocument {
    templateVersion: string;
    cloudPath: string;
    documentKind: DocumentKind;
    state?: StateShort;
}

export enum DocumentKind {
    catExclusions = 'catExclusions',
    stateEns = 'stateEns',
    policyDocument = 'policyDocument',
    declarations = 'declarations',
}

export enum PolicyCancelationReason {
    underwritingDeclination = 'underwritingDeclination',
    insuredRequested = 'insuredRequested',
    noLongerNeed = 'noLongerNeed',
    lapse = 'lapse',
    nonPay = 'nonPay',
    nonRenewal = 'nonRenewal',
    other = 'other',
}

export const policyCancelationReasonText: EnumMap<PolicyCancelationReason> = {
    underwritingDeclination: 'Underwriting Declination',
    insuredRequested: 'Insured Requested',
    noLongerNeed: 'No Longer Need',
    lapse: 'Lapse',
    nonPay: 'Non Pay',
    nonRenewal: 'Non Renewal',
    other: 'Other',
};

export type PolicyWrapperWithEvent = Prisma.PolicyWrapperGetPayload<{
    include: {
        policyEvents: {
            include: {
                initiatingUser: {
                    select: {
                        id: true;
                        firstName: true;
                        lastName: true;
                        email: true;
                        phone: true;
                    };
                };
            };
        };
    };
}>;

// export interface PolicyWrapperWithEventTyped extends PolicyWrapperWithEvent {}
